import * as React from "react"
import Layout from "../components/layout"
import "../main.scss"

const DonatePage = () => {
  return (
    <Layout>
    <header id="about">Donate</header>
    <section id="donate">
      <div>
        <p>PeacePoint depends on generous support provided by people like you to continue our work. Monetary gifts can be made by mail. If you would like to make a gift of securities or other property, please <a href="/contact" className="link">Contact Us</a> to coordinate that transfer. Honorary and memorial gifts are welcome. PeacePoint is a 501(c)(3) ministry so gifts are tax deductible as allowed by law.</p>
        <br/>
        <hr />
        <br/>
        <p><strong>Give by Mail:</strong> Please send contributions by mail to this address.  We will send you a letter acknowledging your gift for tax purposes.</p>
        <div id="address">
          <p>PeacePoint</p>
          <p>PO Box 833</p>
          <p>Blowing Rock, NC 28605</p>
        </div>
        <p><strong>Thank you for supporting PeacePoint!</strong></p>
      </div>
    </section>
    </Layout>
  )
}

export default DonatePage
